import React from 'react';
import {Table, Tag} from 'antd';
import moment from 'moment';
import Button from '../../Widgets/Button';
import Anchor from '../../Widgets/Anchor';

const AdminManagerTable = ({adminUsers, loading, pagination, onPaging}) => {
  const columns = [
    {
      title: '編號',
      render: (_, instance) => (
        <Anchor
          to={`/admin-user/edit/?id=${instance.id}`}>{`#${instance.id}`}</Anchor>
      ),
      width: 50,
      fixed: 'left',
    },
    {
      title: '管理員帳號',
      render: (users) => {
        return (
          <div>
            <div>{users.username}</div>
          </div>
        );
      },
      width: 140,
    },
    {
      title: '管理員名稱',
      render: (users) => {
        return (
          <div>
            <div>{users.name}</div>
          </div>
        );
      },
      width: 140,
    },
    {
      title: '管理員信箱',
      render: (users) => {
        return (
          <div>
            <div>{users.email}</div>
          </div>
        );
      },
      width: 140,
    },
    {
      title: '管理員角色',
      render: (users) => {
        return (
          <div>
            {users.roles?.map((role) => (
              <Tag key={role.id}>{role.name}</Tag>
            ))}
          </div>
        );
      },
      width: 140,
    },
    {
      title: '建立時間',
      render: (_, instance) => ` ${instance.created_at.slice(0, 10)}`,
      width: 100,
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={adminUsers}
      rowKey={(member) => member.id}
      pagination={pagination}
      onChange={onPaging}
    />
  );
};

export default AdminManagerTable;
