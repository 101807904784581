import React, {useEffect, useCallback, useState} from 'react';
import AdminManagerTable from './AdminManagerTable';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../../errors';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import styled from 'styled-components';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import ManagerDialog from '../../Dialogs/ManagerDialog';

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

const defaultAccountInfo = {
  email: '',
  username: '',
  password: '',
  password_confirmation: '',
  name: '',
  roles: [],
};

export default function AdminManagerPage({...props}) {
  const [adminUser, setAdminUser] = useState([]);
  const [accountInfo, setAccountInfo] = useState({...defaultAccountInfo});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');
  const [openDialog, setOpenDialog] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [filters, setFilters] = useState({
    search_str: '',
    role: [],
    ...PAGINATION_INIT,
  });

  const changeFilters = (obj) => {
    setFilters((prev) => ({...prev, ...PAGINATION_INIT, ...obj}));
  };

  const fetchAdminUser = useCallback(async () => {
    let params = {
      role: filters.role,
      search: filters.search_str,
      current: filters.current,
      limit: filters.pageSize,
    };

    if (params.role === 'all') {
      delete params.role;
    }

    setLoading(true);

    try {
      let {
        total_pages,
        current_page,
        count,
        data,
      } = await actions.fetchAdminUser(params);
      setAdminUser(data);
      setTotal(count);
    } catch (err) {
      console.log('err', err);
      Ant.message.error('發生錯誤');
    }
    setLoading(false);
  }, [filters, actions.fetchAdminUser]);

  const fetchRoleList = useCallback(async () => {
    let params = {
      search: filters.search_str,
      current: filters.current,
      limit: filters.pageSize,
    };

    setLoading(true);

    try {
      let {
        total_pages,
        current_page,
        count,
        data,
      } = await actions.fetchRoleList(params);
      setRoleList(data);
    } catch (err) {
      console.log('err', err);
      Ant.message.error('發生錯誤');
    }
    setLoading(false);
  }, [filters, actions.fetchRoleList]);

  const addManager = async () => {
    try {
      setLoading(true);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const {
        username,
        password,
        password_confirmation,
        name,
        roles,
        email,
      } = accountInfo;

      if (!email || !username || !password || !password_confirmation || !name) {
        Ant.message.error('請填寫所有欄位');
        setLoading(false);
        return false;
      }

      if (!emailRegex.test(username)) {
        Ant.message.error('管理者帳號格式需為電子郵件格式');
        setLoading(false);
        return false;
      }

      if (!emailRegex.test(email)) {
        Ant.message.error('電子郵件格式錯誤');
        setLoading(false);
        return false;
      }

      if (password !== password_confirmation) {
        Ant.message.error('兩次密碼不一致');
        setLoading(false);
        return false;
      }

      await actions.addManager({
        username,
        email,
        password,
        password_confirmation,
        name,
        roles,
      });
      Ant.message.success('管理員新增成功');
      return true;
    } catch (err) {
      console.warn(err);
      Ant.message.error('新增失敗');
    }
    setLoading(false);
  };

  const setParantState = (key, value) => {
    setAccountInfo({...accountInfo, [key]: value});
  };

  const onDialogConfirm = async () => {
    const res = await addManager();
    if (res) {
      setOpenDialog(false);
      fetchAdminUser();
    }
  };

  useEffect(() => {
    fetchAdminUser();
  }, [fetchAdminUser]);

  useEffect(() => {
    fetchRoleList();
  }, []);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4 className="short">角色</h4>
            <Ant.Select
              value={filters.role}
              onChange={(val) => changeFilters({role: val})}
              style={{marginRight: 40}}>
              <Ant.Select.Option key="all" value="all">
                全部
              </Ant.Select.Option>
              {roleList.map((option, idx) => (
                <Ant.Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Ant.Select.Option>
              ))}
            </Ant.Select>
            <h4 className="short">搜尋</h4>
            <Ant.Input.Search
              defaultValue={filters.search_str}
              onSearch={(value) => {
                changeFilters({search_str: value});
              }}
              allowClear
              placeholder="搜尋管理員(姓名)"
            />
          </div>
        </div>
        <div className="divider" />
        <div className="row" style={{margin: 0}}>
          <div style={{fontWeight: '500', marginRight: 5}}>共</div>
          <div style={{fontWeight: '500', marginRight: 5}}>{total}</div>
          <div style={{fontWeight: '500', marginRight: 5}}>筆</div>
          <div style={{flex: 1}} />
          <Button
            type="primary"
            onClick={() => setOpenDialog(true)}
            style={{marginRight: 10}}>
            新增管理員
          </Button>
          <Button
            type="primary"
            onClick={fetchAdminUser}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
      </Block>

      <AdminManagerTable
        loading={loading}
        adminUsers={adminUser}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onPaging={(pagination) =>
          changeFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
      <ManagerDialog
        data={accountInfo}
        visible={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={() => onDialogConfirm()}
        onUpdate={setParantState}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
